import { Button, message, PageHeader, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { useApi } from '../hooks/useApi';
import { User } from '../types';

const UserActions = ({ userId, reloadData }: { userId: string; reloadData: () => Promise<void> }) => {
  const { callApi: approveAccount, loading, error } = useApi(`/admin/accounts/${userId}/approve`, 'PUT', false);
  if (error) {
    message.error(error);
  }
  return (
    <Button
      loading={loading}
      onClick={async () => {
        await approveAccount();
        await reloadData();
      }}
    >
      Approve
    </Button>
  );
};

export const UsersPage = () => {
  const { callApi: getUsers, data, loading } = useApi('/admin/accounts', 'GET', true);
  const columns: ColumnsType<User> = [
    { dataIndex: 'firstName', title: 'First Name' },
    { dataIndex: 'lastName', title: 'Last Name' },
    { dataIndex: 'email', title: 'Email' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return !record.approved && <UserActions userId={record.id} reloadData={getUsers} />;
      },
    },
  ];
  return (
    <PageHeader title="Users">
      <Table dataSource={get(data, 'data', [])} columns={columns} loading={loading} />
    </PageHeader>
  );
};
