import { Modal, Form, Input, Alert, Space } from 'antd';
import React, { DOMAttributes, DOMElement, useState } from 'react';
import { api } from '../api';
import { useApiV2 } from '../hooks/useApi';

type Props = {
  btnElement: React.ReactNode;
  agencyId: string;
  reloadData: () => Promise<void>;
};

export const NowCertsAuthModal: React.FC<Props> = ({ agencyId, btnElement, reloadData }) => {
  const { callApi, loading, errorData, error, errorType } = useApiV2(api.agencies.linkNowCerts, false);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  if (errorData) {
    form.setFields(Object.entries(errorData).map(([k, v]) => ({ name: k, errors: v as string[], touched: true })));
  }
  if (error && errorType === useApiV2.errorTypes.CLIENT_ERROR) {
    console.log(error);
    form.setFields([
      {
        name: 'password',
        errors: ['invalid credentials'],
      },
      {
        name: 'username',
        errors: ['invalid credentials'],
      },
    ]);
  }

  return (
    <>
      {React.isValidElement(btnElement) &&
        React.cloneElement(btnElement as DOMElement<DOMAttributes<Element>, Element>, {
          onClick: () => {
            setShowModal(true);
          },
        })}
      <Modal
        visible={showModal}
        confirmLoading={loading}
        title="Link NowCerts"
        onOk={async () => {
          const data = await form.validateFields();
          await callApi(agencyId, data);
          setShowModal(false);
          await reloadData();
        }}
        onCancel={() => setShowModal(false)}
      >
        <Alert
          banner
          type="info"
          message={
            <span>
              Important - please create a dedicated API user in NowCerts for Adapt.{' '}
              <a href="mailto:support@adaptinsurance.com">Reach out to support</a> for help setting it up.
            </span>
          }
          style={{ marginBottom: '20px' }}
        />
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="NowCerts Username" name="username" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="NowCerts Password" name="password" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
