import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const instance = axios.create({ withCredentials: true });

export const api = {
  agencies: {
    linkNowCerts: (agencyId: string, data: Record<string, any>) => {
      return instance.post(`${baseUrl}/ams/nowCerts/login`, { agencyId, ...data });
    },
  },
};
