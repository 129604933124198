import { Layout, Menu } from 'antd';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../components/AuthProvider';

const { Sider, Content } = Layout;
export const DashboardLayout = () => {
  const { pathname } = useLocation();
  const { isMultiAgency, approved, isAdmin } = useAuthContext();
  return approved ? (
    <Layout>
      <Sider>
        <Menu selectedKeys={[pathname]} style={{ height: '100%' }}>
          <Menu.Item key="/carriers">
            <Link to="/carriers">Carriers</Link>
          </Menu.Item>
          <Menu.Item key="/settings">
            <Link to="/settings">Settings</Link>
          </Menu.Item>
          {isMultiAgency && (
            <Menu.Item key="/agencies">
              <Link to="/agencies">Agencies</Link>
            </Menu.Item>
          )}
          {isAdmin && (
            <Menu.Item key="/users">
              <Link to="/users">Users</Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ padding: '24px' }}>
          <Outlet />
        </Content>
        <Layout.Footer>
          <span>
            Need help/have any questions? <a href="mailto:support@adaptinsurance.com">Send us a message</a> and we'll
            get back to you within 24 hours.
          </span>
        </Layout.Footer>
      </Layout>
    </Layout>
  ) : (
    <Layout style={{ padding: '24px' }}>
      <span>
        <a href="https://calendly.com/jacob-simon/adapt-onboarding-call" target="_blank" rel="noreferrer">
          Book an onboarding call{' '}
        </a>{' '}
        (30 mins) to complete account setup.
      </span>
    </Layout>
  );
};
