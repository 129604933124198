import { Button, Descriptions, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { useApi } from '../hooks/useApi';
import { LoginStatus } from '../types';

export const UpdatePortalPasswordModal: React.FC<{
  id: string;
  carrier: string;
  reloadLogins: () => void;
  status: LoginStatus;
}> = ({ id, carrier, reloadLogins, status }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { callApi: updateLogin, loading, error, errorType } = useApi(`/portal-logins/${id}`, 'PUT', false);
  const [form] = Form.useForm();
  if (error && errorType === useApi.errorTypes.CLIENT_ERROR) {
    form.setFields([
      {
        name: 'password',
        errors: ['invalid credentials'],
      },
    ]);
  }
  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Update Password</Button>
      <Modal
        visible={modalOpen}
        title="Update Password"
        footer={null}
        onCancel={() => {
          form.resetFields();
          setModalOpen(false);
        }}
        destroyOnClose
      >
        <Form
          form={form}
          onFinish={async (data) => {
            await updateLogin({ data });
            form.resetFields();
            reloadLogins();
            setModalOpen(false);
          }}
        >
          <Descriptions>
            <Descriptions.Item label="Carrier">{carrier}</Descriptions.Item>
          </Descriptions>
          {status === 'PASSWORD_EXPIRED' && <p>Update your password on the carrier's portal, and provide it below</p>}
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
