import React from 'react';
import './App.css';

import 'antd/dist/antd.min.css';
import './index.css';
import { LoginPage } from './pages/LoginPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/AuthProvider';
import { DashboardLayout } from './pages/DashboardLayout';
import { SettingsPage } from './pages/SettingsPage';
import { CarriersPage } from './pages/CarriersPage';
import { AppLayout } from './pages/AppLayout';
import { SignupPage } from './pages/SignupPage';
import { UnauthenticatedLayout } from './pages/UnauthenticatedLayout';
import { AgenciesPage } from './pages/AgenciesPage';
import { RequestPasswordResetPage } from './pages/RequestPasswordResetPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { UsersPage } from './pages/UsersPage';

function App() {
  return (
    <div className="App" style={{ height: '100%' }}>
      <Routes>
        <Route element={<AppLayout />}>
          <Route element={<UnauthenticatedLayout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/request-password-reset" element={<RequestPasswordResetPage />} />
            <Route path="/reset-password/:linkId" element={<ResetPasswordPage />} />
          </Route>
          <Route element={<AuthProvider />}>
            <Route element={<DashboardLayout />}>
              <Route index element={<Navigate to="/carriers" />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/carriers" element={<CarriersPage />} />
              <Route path="/agencies" element={<AgenciesPage />} />
              <Route path="/users" element={<UsersPage />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
