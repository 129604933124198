import { Button, Descriptions, Form, Input, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { useApi } from '../hooks/useApi';
import { CarrierLogin } from '../types';

export const SecurityQuestionModal: React.FC<{ login: CarrierLogin; reloadLogins: () => void }> = ({
  login,
  reloadLogins,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  // Change the endpoint
  const {
    callApi: updateSecurityQuestions,
    loading,
    error,
    errorType,
  } = useApi(`/portal-logins/${login.id}/security-questions`, 'PUT', false);
  if (error && errorType === useApi.errorTypes.CLIENT_ERROR) {
    // Limitation: we do not know which specific field failed the check
    const errors = [
      {
        name: 'securityQuestionToCheck',
        errors: ['Invalid security question answer'],
      },
    ];
    if (login.secondSecurityQuestionToCheck) {
      errors.push({
        name: 'secondSecurityQuestionToCheck',
        errors: ['Invalid security question answer'],
      });
    }
    form.setFields(errors);
  }
  return (
    <>
      <Tooltip title="Provide an answer to security questions">
        <Button onClick={() => setModalOpen(true)}>Fix</Button>
      </Tooltip>
      <Modal visible={modalOpen} footer={null} title="Link account" onCancel={() => setModalOpen(false)} destroyOnClose>
        <Form
          form={form}
          onFinish={async (data) => {
            await updateSecurityQuestions({
              data: Object.entries(data).map(([question, answer]) => ({ question, answer })),
            });
            form.resetFields();
            reloadLogins();
            setModalOpen(false);
          }}
        >
          <Descriptions>
            <Descriptions.Item label="Carrier">{login.portalSlug}</Descriptions.Item>
          </Descriptions>
          {status === 'PASSWORD_EXPIRED' && (
            <p>{`Answer the following security question${login.secondSecurityQuestionToCheck ?? ''}:`}</p>
          )}
          <Form.Item
            name={login.securityQuestionToCheck}
            label={login.securityQuestionToCheck}
            rules={[{ required: true, message: 'Please provide an answer to the first security question' }]}
          >
            <Input type="text" />
          </Form.Item>
          {login.secondSecurityQuestionToCheck && (
            <Form.Item
              name={login.secondSecurityQuestionToCheck}
              label={login.secondSecurityQuestionToCheck}
              rules={[{ required: true, message: 'Please provide an answer to the second security question' }]}
            >
              <Input type="text" />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
