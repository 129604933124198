import { Button, Col, Form, Input, Row, message } from 'antd';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useApi } from '../hooks/useApi';

export const LoginPage = () => {
  const { callApi: login, loading, data, error } = useApi('/auth/login', 'POST', false);
  if (data) {
    return <Navigate to="/" />;
  }
  if (error) {
    message.error(error);
  }
  return (
    <Row>
      <Col lg={{ span: 8, offset: 8 }} md={{ span: 12, offset: 6 }} xs={{ span: 24, offset: 0 }}>
        <Form
          onFinish={async (data) => {
            await login({ data });
          }}
          layout="vertical"
        >
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Log in
            </Button>
          </Form.Item>
        </Form>
        <Link to="/signup">Register</Link> instead, or <Link to="/request-password-reset">forgot password</Link>
      </Col>
    </Row>
  );
};
