import { Row, Col, Input, Form, Button } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';

export const ResetPasswordPage = () => {
  const { callApi: resetPassword, loading, data, errorData, errorType } = useApi('/users/reset-password', 'PUT', false);
  const { linkId } = useParams();
  const [form] = Form.useForm();
  if (errorType === useApi.errorTypes.VALIDATION_ERROR && errorData) {
    form.setFields(Object.entries(errorData).map(([k, v]) => ({ name: k, errors: v as string[], touched: true })));
  } else if (errorType === useApi.errorTypes.CLIENT_ERROR) {
    form.setFields([{ name: 'password', errors: ['Invalid link, please request a new one'], touched: true }]);
  }
  return (
    <Row>
      <Col span={8} offset={8}>
        {data !== undefined ? (
          <span>
            Password reset. Login <Link to="/login">here</Link>
          </span>
        ) : (
          <Form
            onFinish={async (data) => {
              await resetPassword({ data });
            }}
            layout="vertical"
            initialValues={{ passwordResetLink: linkId }}
            form={form}
          >
            <Form.Item name="passwordResetLink" hidden></Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Reset password
              </Button>
            </Form.Item>
          </Form>
        )}
      </Col>
    </Row>
  );
};
