import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import axiosRetry from 'axios-retry';
import { get } from 'lodash';
import { useCallback, useEffect, useReducer } from 'react';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export enum ErrorType {
  UNAUTHORIZED,
  UNAUTHENTICATED,
  CLIENT_ERROR,
  SERVER_ERROR,
  GENERIC_ERROR,
  VALIDATION_ERROR,
  CONFLICT,
}

const errorCodeMapping: Record<number, ErrorType> = {
  401: ErrorType.UNAUTHENTICATED,
  403: ErrorType.UNAUTHORIZED,
  400: ErrorType.CLIENT_ERROR,
  409: ErrorType.CONFLICT,
  422: ErrorType.VALIDATION_ERROR,
  500: ErrorType.SERVER_ERROR,
};

// Set up Axios retries
function isNetworkOrAuthenticationChallengForIdempotentRequestError(error: AxiosError): boolean {
  return (
    axiosRetry.isNetworkError(error) ||
    (axiosRetry.isIdempotentRequestError(error) && error.response !== undefined && error.response.status === 401)
  );
}

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: isNetworkOrAuthenticationChallengForIdempotentRequestError,
});

const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';

type State<T> = {
  loading: boolean;
  data?: T;
  error?: string;
  errorType?: ErrorType;
  errorData?: Record<string, any>;
};

type Action<T> =
  | { type: typeof LOADING }
  | { type: typeof SUCCESS; payload: T }
  | {
      type: typeof ERROR;
      payload: {
        error?: string;
        errorType?: ErrorType;
        errorData?: Record<string, any>;
      };
    };

function generateReducer<T>() {
  return function (state: State<T>, action: Action<T>): State<T> {
    switch (action.type) {
      case LOADING:
        return {
          loading: true,
          data: undefined,
          error: undefined,
          errorType: undefined,
          errorData: undefined,
        };
      case SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case ERROR:
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
    }
  };
}

function useApiV2<T, S extends (...args: any[]) => Promise<AxiosResponse<T, any>>>(api: S, callOnLoad: boolean) {
  // This line is necessary so we can pass the generic type to the reducer function
  const reducer = generateReducer<T>();
  const [{ loading, data, error, errorData, errorType }, dispatch] = useReducer(reducer, { loading: callOnLoad });

  const callApi = useCallback(async (...apiArgs: Parameters<S>) => {
    try {
      dispatch({ type: LOADING });
      const resp = await api(...apiArgs);
      dispatch({ type: SUCCESS, payload: resp.data });
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const status = get(error, 'response.status');
        const errorType = status ? errorCodeMapping[status] : ErrorType.GENERIC_ERROR;
        dispatch({
          type: ERROR,
          payload: {
            error: error.message,
            errorData: error.response ? error.response.data : undefined,
            errorType: errorType,
          },
        });
      }
      //   rethrow so consuming components don't think it worked
      throw error;
    }
  }, []);
  return { callApi, loading, error, data, errorType, errorData };
}

function useApi<T>(url: string, method: Method, callOnLoad: boolean) {
  // This line is necessary so we can pass the generic type to the reducer function
  const reducer = generateReducer<T>();
  const [{ loading, data, error, errorData, errorType }, dispatch] = useReducer(reducer, { loading: callOnLoad });

  const callApi = useCallback(
    async ({ data }: { data?: Record<string, any> } = {}) => {
      try {
        dispatch({ type: LOADING });
        const resp = await axios.request<T>({ method, url: `${baseUrl}${url}`, withCredentials: true, data });
        dispatch({ type: SUCCESS, payload: resp.data });
      } catch (error) {
        console.error(error);
        if (axios.isAxiosError(error)) {
          const status = get(error, 'response.status');
          const errorType = status ? errorCodeMapping[status] : ErrorType.GENERIC_ERROR;
          dispatch({
            type: ERROR,
            payload: {
              error: error.message,
              errorData: error.response ? error.response.data : undefined,
              errorType: errorType,
            },
          });
        }
        //   rethrow so consuming components don't think it worked
        throw error;
      }
    },
    [url, method],
  );
  useEffect(
    () => {
      if (callOnLoad) {
        callApi();
      }
    },
    [
      /* add fields here if we ever want it to requery automatically */
    ],
  );
  return { callApi, loading, error, data, errorType, errorData };
}

useApi.errorTypes = ErrorType;
useApiV2.errorTypes = ErrorType;
export { useApi, useApiV2 };
