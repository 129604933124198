import { Spin } from 'antd';
import React, { createContext, useContext } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useApi } from '../hooks/useApi';

type AppData = {
  portalSlugs: string[]; // TODO: share types between backend and UI
  additionalLoginFields: Partial<Record<string, Record<string, string>>>;
  isMultiAgency: boolean;
  isAdmin: boolean;
  approved: boolean;
};

const AuthContext = createContext<AppData | undefined>(undefined);

const AuthProvider: React.FC<Record<string, never>> = () => {
  const { data, loading, error, errorType } = useApi<AppData>('/appData', 'GET', true);
  if (loading) {
    return <Spin />;
  }
  if (error) {
    if (errorType === useApi.errorTypes.UNAUTHENTICATED) {
      return <Navigate to="login" />;
    }
  }
  return (
    <AuthContext.Provider value={data}>
      <Outlet />
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an auth provider');
  }
  return context;
};

export { AuthProvider, useAuthContext };
