import { Button, Layout } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useApi } from '../hooks/useApi';

export const AppLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { callApi: logout, loading } = useApi('/auth/logout', 'POST', false);
  return (
    <Layout style={{ height: '100%' }}>
      <Layout.Header className="header" style={{ display: 'flex' }}>
        <div className="logo" style={{ float: 'left', color: 'white' }}>
          Adapt
        </div>
        {/* Hacky but good enough for now.  Once we build a proper auth abstraction we should refactor */}
        {!['/login', '/signup', '/request-password-reset', '/reset-password'].find((s) => pathname.includes(s)) && (
          <Button
            type="link"
            loading={loading}
            style={{ marginLeft: 'auto', alignSelf: 'center' }}
            onClick={async () => {
              await logout();
              navigate('/login');
            }}
          >
            Logout
          </Button>
        )}
      </Layout.Header>
      <Outlet />
    </Layout>
  );
};
