import { Button, Col, Form, Input, Row } from 'antd';
import { useApi } from '../hooks/useApi';

export const RequestPasswordResetPage = () => {
  const { callApi: requestReset, loading, data } = useApi('/users/request-password-reset', 'POST', false);
  console.log(data);
  return (
    <Row>
      <Col span={8} offset={8}>
        {data !== undefined ? (
          <span>Check your inbox, if an account for that email exists you will receive a reset link</span>
        ) : (
          <Form
            onFinish={async (data) => {
              await requestReset({ data });
            }}
            layout="vertical"
          >
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Request reset link
              </Button>
            </Form.Item>
          </Form>
        )}
      </Col>
    </Row>
  );
};
