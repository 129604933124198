import { Button, Descriptions, Input, Popover, Space, Spin } from 'antd';
import { useApi } from '../hooks/useApi';
import { useAuthContext } from '../components/AuthProvider';
import { Agency } from '../types';
import { get, startCase } from 'lodash';
import { NowCertsAuthModal } from '../components/NowCertsAuthModal';
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';

type User = {
  publicApiKey: string;
  secretApiKey: string;
  testSecretApiKey: string;
  referralCode: string;
};

const referralProgramContent = (
  <div>
    <span>Each agency you refer to Adapt gets a free month.</span>
    <br />
    <span>If they sign on as a customer at the end of that month</span>
    <br />
    <span>you'll get a free month too!</span>
  </div>
);

export const SettingsPage = () => {
  const { data, loading } = useApi('/users/profile', 'GET', true);
  const { data: agenciesData, callApi } = useApi<{ data: Agency[] }>('/agencies', 'GET', true);
  const { isMultiAgency } = useAuthContext();
  const agencies = get(agenciesData, 'data');

  if (loading) return <Spin />;
  const { secretApiKey, testSecretApiKey, referralCode } = data as User;
  const referralLink = `${window.location.origin}/signup?referralCode=${referralCode}`;
  return (
    <Space direction="vertical">
      <Descriptions labelStyle={{ alignItems: 'center' }} column={1}>
        {!isMultiAgency && agencies && (
          <Descriptions.Item label="Management System">
            <Space>
              {agencies[0].amsLink && (
                <span>{startCase(agencies[0].amsLink.managementSystemId.toLowerCase())} linked </span>
              )}
              <NowCertsAuthModal
                agencyId={agencies[0].id}
                btnElement={<Button>{agencies[0].amsLink ? 'Update' : 'Link'} Management System</Button>}
                reloadData={callApi}
              />
            </Space>
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={
            <Popover content={referralProgramContent} title="Adapt Referral Program" placement="topRight">
              <span>Referral link </span>
              <InfoCircleOutlined />
            </Popover>
          }
        >
          <Input
            readOnly
            value={referralLink}
            addonAfter={<CopyOutlined onClick={() => navigator.clipboard.writeText(referralLink)} />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Test Secret Key">
          <Input
            readOnly
            value={testSecretApiKey}
            type="password"
            addonAfter={<CopyOutlined onClick={() => navigator.clipboard.writeText(testSecretApiKey)} />}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Live Secret Key">
          <Input
            readOnly
            value={secretApiKey}
            type="password"
            addonAfter={<CopyOutlined onClick={() => navigator.clipboard.writeText(secretApiKey)} />}
          />
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
};
