import React from 'react';
import { Button, message, PageHeader, Table } from 'antd';
import { useApi } from '../hooks/useApi';
import { ColumnsType } from 'antd/lib/table';
import { get, startCase } from 'lodash';
import { AgencyModal } from '../components/AgencyModal';
import { Agency } from '../types';
import { useAuthContext } from '../components/AuthProvider';
import { NowCertsAuthModal } from '../components/NowCertsAuthModal';

const AgencyActions = ({ agency, reloadData }: { agency: Agency; reloadData: () => Promise<void> }) => {
  const { callApi, loading, errorData } = useApi(`/agencies/${agency.id}`, 'PUT', false);
  const { isAdmin } = useAuthContext();
  const {
    callApi: sendTestEmail,
    loading: testEmailLoading,
    error: testEmailError,
  } = useApi(`/agencies/${agency.id}/test-send-notice-report`, 'POST', false);
  const {
    callApi: sendEmail,
    loading: emailLoading,
    error: emailError,
  } = useApi(`/agencies/${agency.id}/send-notice-report`, 'POST', false);
  if (emailError || testEmailError) {
    // TODO: figure out why this shows so many times
    message.error(emailError || testEmailError);
  }
  return (
    <>
      <AgencyModal
        submit={async (data) => {
          await callApi(data);
          await reloadData();
        }}
        agency={agency}
        errorData={errorData}
        loading={loading}
      >
        <Button>Edit</Button>
      </AgencyModal>
      {isAdmin && (
        <>
          <Button onClick={() => sendTestEmail()} loading={testEmailLoading}>
            Test Report
          </Button>
          {agency.email && (
            <Button onClick={() => sendEmail()} loading={emailLoading}>
              Send Report
            </Button>
          )}
        </>
      )}
    </>
  );
};

export const AgenciesPage = () => {
  const { data, loading, callApi } = useApi('/agencies', 'GET', true);
  const { callApi: create, loading: createLoading, errorData: createError } = useApi('/agencies', 'POST', false);
  const columns: ColumnsType<Agency> = [
    { dataIndex: 'name', title: 'Name' },
    { dataIndex: 'email', title: 'Email' },
    {
      title: 'Management System',
      key: 'ams',
      render: (_, record) => {
        return record.amsLink ? (
          <span>{startCase(record.amsLink.managementSystemId.toLowerCase())} linked</span>
        ) : (
          <NowCertsAuthModal agencyId={record.id} btnElement={<Button>Link NowCerts</Button>} reloadData={callApi} />
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return <AgencyActions agency={record} reloadData={callApi} />;
      },
    },
  ];
  return (
    <PageHeader
      title="Agencies"
      extra={[
        <AgencyModal
          submit={async (data) => {
            await create(data);
            await callApi();
          }}
          loading={createLoading}
          errorData={createError}
        >
          <Button type="primary" key="add">
            Add
          </Button>
        </AgencyModal>,
      ]}
    >
      <Table dataSource={get(data, 'data', [])} columns={columns} loading={loading} />
    </PageHeader>
  );
};
