import { Layout } from 'antd';
import { Outlet } from 'react-router';

export const UnauthenticatedLayout = () => (
  <Layout>
    <Layout.Content style={{ margin: '24px' }}>
      <Outlet />
    </Layout.Content>
  </Layout>
);
