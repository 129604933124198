import { Form, Input, Button, Row, Col, message } from 'antd';
import { Navigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
export const SignupPage = () => {
  const { callApi: signup, loading, data, errorData, error, errorType } = useApi('/users/signup', 'POST', false);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const referredBy = searchParams.get('referralCode');
  if (data) {
    return <Navigate to="/" />;
  }
  if (errorType === useApi.errorTypes.VALIDATION_ERROR && errorData) {
    form.setFields(Object.entries(errorData).map(([k, v]) => ({ name: k, errors: v as string[], touched: true })));
  }
  if (error && errorType === useApi.errorTypes.CONFLICT) {
    const errorMsg = (errorData as { message: string }).message;
    message.error({ content: errorMsg, key: errorMsg });
  }
  return (
    <Row>
      <Col lg={{ span: 8, offset: 8 }} md={{ span: 12, offset: 6 }} xs={{ span: 24, offset: 0 }}>
        <Form
          onFinish={async (data) => {
            await signup({ data });
          }}
          form={form}
          layout="vertical"
        >
          <Form.Item label="Email" name="email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item label="Company" name="organizationName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item hidden name="referredBy" initialValue={referredBy} />
          <Form.Item name="terms">
            <small>
              I confirm I have read and agree to Adapt API’s{' '}
              <a href="https://adaptinsurance.com/privacy-policy" target="_blank">
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href="https://adaptinsurance.com/terms-of-use" target="_blank">
                Terms of Use
              </a>
              .
              <br />
              By clicking “Sign up”, you expressly agree to the terms above.
            </small>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Sign up
            </Button>
          </Form.Item>
        </Form>
        Or <Link to="/login">Log in</Link> instead
      </Col>
    </Row>
  );
};
