import { Modal, Form, Input } from 'antd';
import React, { DOMAttributes, DOMElement, useState } from 'react';
import { Agency } from '../types';

type Props = {
  submit: (opts: { data: Record<string, any> }) => Promise<void>;
  agency?: Agency;
  errorData: Record<string, any> | undefined;
  loading: boolean;
};

export const AgencyModal: React.FC<Props> = ({ submit, agency = {}, errorData, loading, children }) => {
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  if (errorData) {
    form.setFields(Object.entries(errorData).map(([k, v]) => ({ name: k, errors: v as string[], touched: true })));
  }

  const btnElement = React.Children.only(children);
  return (
    <>
      {React.isValidElement(btnElement) &&
        React.cloneElement(btnElement as DOMElement<DOMAttributes<Element>, Element>, {
          onClick: () => {
            setShowModal(true);
          },
        })}
      <Modal
        visible={showModal}
        confirmLoading={loading}
        title="Add an agency"
        onOk={async () => {
          const data = await form.validateFields();
          await submit({ data });
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
      >
        <Form form={form} initialValues={agency} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="Agency Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
